<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t('content.mtrans') }}</h5>
                <div>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                    <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                  </material-button>
                </div>
              </div>
            </div>

          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th class="align-top">#</th>
                      <th class="align-top" style="min-width: 150px;">{{ $t('content.mtrans') }}</th>
                      <th class="align-top" style="min-width: 150px;">{{ $t('content.transc') }}</th>
                      <th class="align-top text-center" colspan="3">{{ $t('apps.gtransaction') }}</th>
                      <th class="align-top text-center" colspan="3">{{ $t('content.fromt') }}</th>
                      <th class="align-top text-center" colspan="3">{{ $t('content.tot') }}</th>
                      <th class="align-top">{{ $t('content.act') }}</th>
                    </tr>
                    <tr>
                      <th colspan="3"></th>
                      <th class="align-top text-center">
                        {{ $t("content.amndeposit") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.amnwithdraw") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.amntransfer") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("apps.mcash") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.mbank") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.tunnecessary") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("apps.mcash") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.mbank") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.tunnecessary") }}
                      </th>
                      <th class="align-top"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="group-id-ticket">
                      <td class="text-sm font-weight-normal">
                        <div class="group-tickect">
                          <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal" colspan="11">
                        <material-input v-model="name_search" type="text" class="mb-0" :label="$t('content.detail')" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch" @click="loadDataApi">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-if="datas.addNew">
                      <td class="text-sm font-weight-normal">#</td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="finance_detail.name" type="text" class="mb-0" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <select v-model="finance_detail.finCat" class="form-select">
                          <option value="" disabled selected>{{ $t('content.select') }}</option>
                          <option v-for="(a, opt1) in datas.FinancAll" :key="opt1" :value="a.val">
                            {{ a.items }}
                          </option>
                        </select>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.txDeposit" :checked="finance_detail.txDeposit" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.txWithdrawal"
                          :checked="finance_detail.txWithdrawal" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.txTransfer" :checked="finance_detail.txTransfer" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.srcCashAccount"
                          :checked="finance_detail.srcCashAccount" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.srcBankAccount"
                          :checked="finance_detail.srcBankAccount" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.srcNone" :checked="finance_detail.srcNone" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.dstCashAccount"
                          :checked="finance_detail.dstCashAccount" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.dstBankAccount"
                          :checked="finance_detail.dstBankAccount" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.dstNone" :checked="finance_detail.dstNone" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="finance_detail.name === '' || finance_detail.finCat === ''
                            || (!finance_detail.txDeposit && !finance_detail.txWithdrawal && !finance_detail.txTransfer)
                            || (!finance_detail.srcCashAccount && !finance_detail.srcBankAccount && !finance_detail.srcNone)
                            || (!finance_detail.dstCashAccount && !finance_detail.dstBankAccount && !finance_detail.dstNone)" @click="saveNewItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelSaveNewItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-for="(row, index) in listfinance" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        {{ row.name }}
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        {{ checkFinCat(row.finCat) }}
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox :checked="row.txDeposit" disabled />
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox :checked="row.txWithdrawal" disabled />
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox :checked="row.txTransfer" disabled />
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox :checked="row.srcCashAccount" disabled />
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox :checked="row.srcBankAccount" disabled />
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox :checked="row.srcNone" disabled />
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox :checked="row.dstCashAccount" disabled />
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox :checked="row.dstBankAccount" disabled />
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox :checked="row.dstNone" disabled />
                      </td>
                      <td v-if="row.uid !== finance_detail.uid" class="text-sm font-weight-normal">
                        <material-button color="info" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="btnEdit" @click="editRowItems(row)">
                          <i class="material-icons material-symbols-outlined">edit</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel" @click="deleteRowItems(row)">
                          <i class="material-icons material-symbols-outlined">delete</i>
                        </material-button>
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-input v-model="finance_detail.name" type="text" class="mb-0" />
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <select v-model="finance_detail.finCat" class="form-select" disabled>
                          <option value="" disabled selected>{{ $t('content.select') }}</option>
                          <option v-for="(a, opt1) in datas.FinancAll" :key="opt1" :value="a.val">
                            {{ a.items }}
                          </option>
                        </select>
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.txDeposit" :checked="finance_detail.txDeposit" />
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.txWithdrawal"
                          :checked="finance_detail.txWithdrawal" />
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.txTransfer" :checked="finance_detail.txTransfer" />
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.srcCashAccount"
                          :checked="finance_detail.srcCashAccount" />
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.srcBankAccount"
                          :checked="finance_detail.srcBankAccount" />
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.srcNone" :checked="finance_detail.srcNone" />
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.dstCashAccount"
                          :checked="finance_detail.dstCashAccount" />
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.dstBankAccount"
                          :checked="finance_detail.dstBankAccount" />
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-checkbox v-model="finance_detail.dstNone" :checked="finance_detail.dstNone" />
                      </td>
                      <td v-if="row.uid === finance_detail.uid" class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="finance_detail.name === '' || finance_detail.finCat === ''
                            || (!finance_detail.txDeposit && !finance_detail.txWithdrawal && !finance_detail.txTransfer)
                            || (!finance_detail.srcCashAccount && !finance_detail.srcBankAccount && !finance_detail.srcNone)
                            || (!finance_detail.dstCashAccount && !finance_detail.dstBankAccount && !finance_detail.dstNone)" @click="saveEditRowItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelEditRowItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                    </tr>
                    <tr v-if="listfinance.length === 0">
                      <td colspan="14" class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="14" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <pre></pre>
            <div style="display: flex; justify-content: flex-end">
              <ul v-show="listfinance.length > 0" class="pagination justify-content-end">
                <div class="project-container">
                  <div class="example-one">
                    <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                      @click="onClickHandler" />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, reactive, inject, computed } from 'vue'
import { useI18n } from "vue-i18n"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import ExampleFive from "@/examples/ExampleOne";
import { useFinancialTranTypeStore } from '@/stores/financialtrantype'

const storef = useFinancialTranTypeStore()

const { t } = useI18n()
const swal = inject('$swal')

const datas = reactive({
  addNew: false,
  rowEdit: false,
  demo1: [
    { items: "ประเภท1.1" },
    { items: "ประเภท1.2" },
    { items: "ประเภท1.3" },
    { items: "ประเภท1.4" },
    { items: "ประเภท1.5" },
    { items: "ประเภท1.6" }
  ],
  FinancAll: [
    { items: computed(() => t("content.ctranstin")), val: 'INTERNAL' },
    { items: computed(() => t("content.ctranstout")), val: 'EXTERNAL' },
    { items: computed(() => t("content.direct_lo")), val: 'DIRECTOR_LOAN' },
    { items: computed(() => t("content.sell_pr")), val: 'SELLING_PRODUCTS' },
    { items: computed(() => t("content.fee")), val: 'FEE' },
    { items: computed(() => t("content.ctranstexp")), val: 'EXPENSE' },
    { items: computed(() => t("content.ctranstacc")), val: 'ACCOUNT_CLOSURE' },
    { items: computed(() => t("content.acc_adj")), val: 'ACCOUNT_ADJUST' },
  ],
})

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const name_search = ref('');
const listfinance = ref([]);

const finance_detail = ref({
  uid: '',
  name: '',
  finCat: '',
  version: '',
  txDeposit: false,
  txWithdrawal: false,
  txTransfer: false,
  srcCashAccount: false,
  srcBankAccount: false,
  srcNone: false,
  dstCashAccount: false,
  dstBankAccount: false,
  dstNone: false,
  shopUid: '',
  groupUid: ''
})

const key = JSON.parse(localStorage.getItem("shop_seleted"));


const onClickHandler = (page) => {
  // console.log('onClickHandler :' + page);
  updatePagination()
};

const loadDataApi = async (val) => {

  let data = "";
  if (currentPage.value !== 1) {
    if (listfinance.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }
  if (name_search.value !== '' && val !== 'del') {
    currentPage.value = 1
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: name_search.value,
    shopUid: key.uid
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storef.financialTranTypeListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listfinance.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}

loadDataApi()

async function updatePagination() {
  listfinance.value = []
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: name_search.value,
    shopUid: key.uid
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storef.financialTranTypeListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listfinance.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}

function checkFinCat(str) {
  let name = ''
  let ld = datas.FinancAll.filter((c) => c.val === str);
  if (ld.length > 0) {
    name = ld[0].items
  }
  return name
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}


function clearItem() {
  datas.addNew = false
  datas.rowEdit = false
  finance_detail.value = {
    uid: '',
    name: '',
    finCat: '',
    version: '',
    txDeposit: false,
    txWithdrawal: false,
    txTransfer: false,
    srcCashAccount: false,
    srcBankAccount: false,
    srcNone: false,
    dstCashAccount: false,
    dstBankAccount: false,
    dstNone: false,
    shopUid: '',
    groupUid: ''

  }
}



async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
      datas.addNew = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.addNew = false
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addFinTrType()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      clearItem()
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
    }
  })
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
      finance_detail.value = {
        uid: item.uid,
        name: item.name,
        finCat: item.finCat,
        version: item.version,
        txDeposit: item.txDeposit,
        txWithdrawal: item.txWithdrawal,
        txTransfer: item.txTransfer,
        srcCashAccount: item.srcCashAccount,
        srcBankAccount: item.srcBankAccount,
        srcNone: item.srcNone,
        dstCashAccount: item.dstCashAccount,
        dstBankAccount: item.dstBankAccount,
        dstNone: item.dstNone,
        groupUid: item.groupUid,
        shopUid: item.shopUid
      }
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      clearItem()
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      updateFinTrType()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      clearItem()
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
    }
  })
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteFinTrType(item.uid)
      }
    }
  });
}

async function addFinTrType() {
  const payload = finance_detail.value;
  payload.groupUid = key.groupUid
  payload.shopUid = key.uid
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storef.financialTranTypeAddFetch(payload);
  // console.log('loadDataApi : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItem()
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('add')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateFinTrType() {
  const payload = finance_detail.value;
  payload.groupUid = key.groupUid
  payload.shopUid = key.uid
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storef.financialTranTypeUpdateFetch(payload);
  // console.log('loadDataApi : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItem()
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deleteFinTrType(id) {
  let data = "";
  data = {
    uid: id,
    groupUid: key.groupUid,
    shopUid: key.uid
  };
  const payload = data;

  const response = await storef.financialTranTypeDeleteFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }

}




</script>

<style lang="scss" scoped></style>