/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useShopHolidayStore = defineStore("shopholiday", {
  actions: {
    async shopHolidayListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "holidayDate",
        },
        filter: {
          "holidayDate:ge": payload.holidayDate + "-01-01", //yyyy-MM-dd
          "holidayDate:le": payload.holidayDate + "-12-31",
          shopUid: payload.shopUid,
        },
      };

      return new Promise((resolve, reject) => {
        try {
          console.log("body : " + JSON.stringify(body));
          const pathUrl = "/user-svc/shop-holiday/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopHolidayInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-holiday/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopHolidayAddFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        holidayDate: payload.holidayDate,
        description: payload.description,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-holiday/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopHolidayUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        shopUid: payload.shopUid,
        holidayDate: payload.holidayDate,
        description: payload.description,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-holiday/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopHolidayDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-holiday/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
